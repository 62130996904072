import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ThemeSelector from './components/themes/ThemeSelector';
import store from './store/reduxStore';
import { LIGHT_MODE } from './config/Constants';
import $ from 'jquery';
import { apiConfig } from './config/Config';
import { ProSidebarProvider } from 'react-pro-sidebar';

var scriptFiles = [
  '../../../jquery/resourceManager.min.js',
  'https://ajax.aspnetcdn.com/ajax/jQuery/jquery-3.3.1.min.js',
  'https://ajax.aspnetcdn.com/ajax/jquery.migrate/jquery-migrate-3.0.0.min.js',
  '../../../jquery/settings.js',
  'https://kendo.cdn.telerik.com/2024.1.130/js/kendo.all.min.js',
  'https://kendo.cdn.telerik.com/2024.1.130/js/kendo.timezones.min.js',
  'https://unpkg.com/jszip/dist/jszip.min.js',
  'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.10.2/moment.min.js',
  '../../../jquery/allScripts.min.js',
  // '../../../jquery/Resources/en/res.CommonResources.en.js',
  // '../../../jquery/Resources/en/res.CommonResources.en.custom.js',
  // '../../../jquery/Resources/en/res.CommonRes.en.js',
  // '../../../jquery/Resources/en/res.CommonEnums.en.js',
  // '../../../jquery/Resources/en/res.DialogResource.en.js',
  // '../../../jquery/icoroll.js',
  // '../../../jquery/common.js',
  // '../../../jquery/FormHelper.js',
  // '../../../jquery/jconfig.js',
  // '../../../jquery/jconfig.custom.js',
  // '../../../jquery/jquery.grideditor.js',
  // '../../../jquery/jquery.kendospreadsheet.js',
  // '../../../jquery/jquery.kendospreadsheet.custom.js',
  // '../../../jquery/jquery.kendohelper.js',
  // '../../../jquery/jquery.kendohelper.custom.js',
  // '../../../jquery/jquery.kendoutils.js',
  // '../../../jquery/filterManager.js',
  // '../../../jquery/QuickSearch.js',
  // '../../../jquery/grid.js',
  // '../../../jquery/gridWithDetail.js',
  // '../../../jquery/completeGrid.js',
  // '../../../jquery/jquery.kendoextension.js',
  '../../../jquery/templates/KendoTemplates.html',
  '../../../jquery/templates/KendoTemplates.custom.html',
];
function continueloadscript(scriptFiles) {
  if (scriptFiles.length > 0) {
    loadscript(scriptFiles.shift());
  } else {
    window['setserviceurl'](apiConfig.endPoint + '/api/');
    window['createnotification']();
  }
}

function loadscript(file) {
  if (file.endsWith('.js')) {
    if (file.startsWith('../')) {
      $.getScript(file).done(function () {
        continueloadscript(scriptFiles);
      });
    } else {
      $.ajax({
        url: file,
        dataType: 'script',
        cache: true,
      }).done(function () {
        continueloadscript(scriptFiles);
      });
    }
  } else {
    $.get(file).done(function (result) {
      $('body').append(result);
      continueloadscript(scriptFiles);
    });
  }
}
loadscript(scriptFiles.shift());

(async () => {
  const currentTheme = localStorage.getItem('currentTheme');
  if (!currentTheme || currentTheme === LIGHT_MODE) {
    await import('./components/themes/LightTheme');
  } else {
    await import('./components/themes/DarkTheme');
  }
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <ProSidebarProvider>
          <ThemeSelector>
            <App />
          </ThemeSelector>
        </ProSidebarProvider>
      </Provider>
    </BrowserRouter>,
  );
})();
