import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export interface IJumboBoxProps {
  styleName?: string;
  children: React.ReactNode;
}
const JumboBox = (props: IJumboBoxProps): React.ReactElement => {
  return (
    <div className={`p-2 ${props.styleName}`}>
      <Container className="welcome-header">
        <Row>
          <Col className='pt-3 ps-3 pe-4'>{props.children}</Col>
        </Row>
      </Container>
    </div>
  );
};
export default JumboBox;

export interface IJumboImageBoxProps extends IJumboBoxProps {
  imageSource: string;
}

export const JumboImageBox = (props: IJumboBoxProps): React.ReactElement => {
  return (
    <div className={`p-4`}>
      <Container className={`${props.styleName}`}>
        <Row>
          <Col md={2}></Col>
          <Col>{props.children}</Col>
        </Row>
      </Container>
    </div>
  );
};
