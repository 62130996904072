import { IMenuItemProps } from '../components/services/GraphQLShared';

export const sidebar_menu: IMenuItemProps[] = [
  { id: 0, title: 'Home', icon: 'home', route: '/', key: '' },
  {
    title: 'Budgeting Tool',
    key: 'BudgertTool',
    icon: 'chart-bar',
    color: '#0a2756',
    route: '#',
    id: 1,
  },
  {
    title: 'Budgeting Reports',
    key: 'BudgetReports',
    icon: 'file-text',
    color: '#0080ff',
    route: '#',
    id: 20,
  },
  {
    title: 'Budgeting Settings',
    key: 'BudgetingSettings',
    icon: 'sliders-h',
    color: '#ffa800',
    route: '#',
    id: 21,
  },
  {
    title: 'Annual Budget',
    key: 'AnnualBudget',
    icon: 'calculator',
    route: '/Trading/AnnualBudget/AnnualBudget',
    id: 2,
    parentId: 1,
  },
  {
    title: 'Options and Credits',
    key: 'OptionsCredits',
    icon: 'tablet-alt',
    route: '/Trading/AnnualBudget/OptionsCredits',
    id: 3,
    parentId: 1,
  },
  {
    title: 'DDL Budget',
    key: 'DDLBudget',
    icon: 'calculator',
    route: '/Trading/AnnualBudget/DDLBudget',
    id: 2,
    parentId: 1,
  },
  //{
  //  title: 'Options and Credits (Old)',
  //  key: 'OptionsCreditsOld',
  //  icon: 'tablet-alt',
  //  route: '/Trading/AnnualBudget/OptionsCreditsOld',
  //  id: 4,
  //  parentId: 1,
  //},
  //{
  //  title: 'Direct Response',
  //  key: 'DirectResponse',
  //  icon: 'tablet-alt',
  //  route: '/Trading/AnnualBudget/DirectResponse',
  //  id: 5,
  //  parentId: 1,
  //},
  //{
  //  title: 'Cancelations + Shifts',
  //  key: 'CancelBudget',
  //  icon: 'tablet-alt',
  //  route: '/Trading/AnnualBudget/CancelBudget',
  //  id: 6,
  //  parentId: 1,
  //},
  //{
  //  title: 'Options Tracker',
  //  key: 'OptionsTracker',
  //  icon: 'tablet-alt',
  //  route: '/Trading/AnnualBudget/OptionsTracker',
  //  id: 7,
  //  parentId: 1,
  //},
  {
    title: 'Agency Spend Report',
    key: 'AgencyReporting',
    icon: 'file-text',
    route: '/Report/Index/AgencyReport',
    id: 8,
    parentId: 20,
  },
  {
    title: 'Agency Spend Report(A)',
    key: 'AgencyAdminReport',
    icon: 'file-text',
    route: '/Report/Index/AgencyAdminReport',
    id: 9,
    parentId: 20,
  },
  {
    title: 'Portal Report',
    key: 'PortalReporting',
    icon: 'file-text',
    route: '/Report/Index/PortalReport',
    id: 10,
    parentId: 20,
  },
  //{
  //  title: 'Direct Response Report',
  //  key: 'DirectResponseReport',
  //  icon: 'tablet-alt',
  //  route: '/Report/Index/DirectResponseReport',
  //  id: 11,
  //  parentId: 1,
  //},
  {
    title: 'Data Viewer',
    key: 'ViewerReporting',
    icon: 'chart-pie',
    route: '/Trading/AnnualBudget/Viewer',
    id: 12,
    parentId: 20,
  },
  {
    title: 'Client Report',
    key: 'ClientReporting',
    icon: 'file-text',
    route: '/Report/Index/ClientReport',
    id: 13,
    parentId: 20,
  },
  {
    title: 'DDL Deals Report',
    key: 'DDLReport',
    icon: 'file-text',
    route: '/Report/Index/DDLReport',
    id: 9,
    parentId: 20,
  },
  {
    title: 'Deals',
    key: 'DealCommitmentUS',
    icon: 'tablet-alt',
    route: '/Trading/DealCommitment/Index',
    id: 14,
    parentId: 21,
  },
  {
    title: 'Budget Periods',
    key: 'AgencyProfilePeriodUS',
    icon: 'list-ol',
    route: '/Trading/AgencyProfile/IndexBudgetPeriod',
    id: 15,
    parentId: 21,
  },
  {
    title: 'Agency Profiles',
    key: 'AgencyProfileUS',
    icon: 'folder-tree',
    route: '/Trading/AgencyProfile/Index',
    id: 16,
    parentId: 21,
  },
  {
    title: 'Client Profiles',
    key: 'ClientProfileUS',
    icon: 'sliders-h',
    route: '/Trading/ClientProfile/Index',
    id: 16,
    parentId: 21,
  },
  {
    title: 'Media Networks Profiles',
    key: 'MediaNetworkProfileUS',
    icon: 'sliders-h',
    route: '/Trading/MediaNetworkProfile/Index',
    id: 16,
    parentId: 21,
  },
  //{
  //  title: 'Budgeting Change Management',
  //  key: 'BudgetingChange',
  //  icon: 'check-circle',
  //  route: '#',
  //  id: 22,
  //},
  //{
  //  title: 'Approve changes',
  //  key: 'AnnualBudgetLineChangeApproveUS',
  //  icon: 'check-circle',
  //  route: '/Trading/AnnualBudgetLineChangeApprove/Index',
  //  id: 17,
  //  parentId: 22,
  //},
  //{
  //  title: 'Budget Modification History',
  //  key: 'AnnualBudgetLineChangeUS',
  //  icon: 'list-ol',
  //  route: '/Trading/AnnualBudgetLineChange/Index',
  //  id: 18,
  //  parentId: 22,
  //},
  //{
  //  title: 'Direct Response Upload',
  //  key: 'DirectResponseUpload',
  //  icon: 'tablet-alt',
  //  route: '/Trading/AnnualBudget/DirectResponseUpload',
  //  id: 17,
  //  parentId: 1,
  //},
  //{
  //  title: 'Direct Response Mappings',
  //  key: 'DirectResponseMappings',
  //  icon: 'tablet-alt',
  //  route: '/Trading/DirectResponseMapping/Index',
  //  id: 18,
  //  parentId: 1,
  //},
  {
    title: 'Master Data',
    key: 'Master',
    icon: 'boxes',
    route: '#',
    color: '#00b5b1',
    id: 50,
  },
  {
    title: 'Agencies',
    key: 'CompanyUSA',
    icon: 'tablet-alt',
    route: '/Master/Company/Index',
    id: 51,
    parentId: 50,
  },
  {
    title: 'Clients',
    key: 'ClientUSA',
    icon: 'tablet-alt',
    route: '/Master/Client/Index',
    id: 52,
    parentId: 50,
  },
  {
    title: 'Master Clients',
    key: 'MasterClientUSA',
    icon: 'tablet-alt',
    route: '/Master/MasterClient/Index',
    id: 53,
    parentId: 50,
  },
  {
    title: 'Business Sectors',
    key: 'SectorUSA',
    icon: 'tablet-alt',
    route: '/Master/Sector/Index',
    id: 54,
    parentId: 50,
  },
  {
    title: 'Demos',
    key: 'AudienceUSA',
    icon: 'tablet-alt',
    route: '/Master/Audience/Index',
    id: 55,
    parentId: 50,
  },
  {
    title: 'Media Networks',
    key: 'MediaNetworkUSA',
    icon: 'tablet-alt',
    route: '/Master/MediaNetwork/Index',
    id: 56,
    parentId: 50,
  },
  {
    title: 'Media Groups',
    key: 'MediaGroupUSA',
    icon: 'tablet-alt',
    route: '/Master/MediaGroup/Index',
    id: 57,
    parentId: 50,
  },
  {
    title: 'Day Parts',
    key: 'DayPart',
    icon: 'tablet-alt',
    route: '/Master/DayPart/Index',
    id: 60,
    parentId: 50,
  },
  {
    title: 'Markets',
    key: 'Market',
    icon: 'tablet-alt',
    route: '/Master/Market/Index',
    id: 61,
    parentId: 50,
  },
  //{
  //  title: 'Event Types',
  //  key: 'EventType',
  //  icon: 'tablet-alt',
  //  route: '/Master/EventType/Index',
  //  id: 62,
  //  parentId: 50,
  //},
  {
    title: 'Master Media Types',
    key: 'MasterMediaType',
    icon: 'tablet-alt',
    route: '/Master/MasterMediaType/Index',
    id: 67,
    parentId: 50,
  },
  {
    title: 'Media Types',
    key: 'DistributionType',
    icon: 'tablet-alt',
    route: '/Master/DistributionType/Index',
    id: 63,
    parentId: 50,
  },
  {
    title: 'Ad Types',
    key: 'AdTypeUSA',
    icon: 'tablet-alt',
    route: '/Master/AdType/Index',
    id: 64,
    parentId: 50,
  },
  {
    title: 'Ad Durations',
    key: 'AdDuration',
    icon: 'tablet-alt',
    route: '/Master/AdDuration/Index',
    id: 65,
    parentId: 50,
  },
  {
    title: 'Program Types',
    key: 'ProgramTypeUSA',
    icon: 'tablet-alt',
    route: '/Master/ProgramType/Index',
    id: 66,
    parentId: 50,
  },
  {
    title: 'Security',
    key: 'SecurityMenu',
    icon: 'users',
    route: '#',
    id: 100,
  },
  {
    title: 'Roles',
    key: 'Roles',
    icon: 'tablet-alt',
    description: 'Manage security roles',
    route: '/Framework/Role/Index',
    id: 101,
    parentId: 100,
  },
  {
    title: 'Security Groups',
    key: 'SecurityGroups',
    icon: 'tablet-alt',
    description: 'Manage security groups',
    route: '/Framework/Group/Index',
    id: 102,
    parentId: 100,
  },
  {
    title: 'Users',
    key: 'Users',
    icon: 'tablet-alt',
    description: 'Manage users',
    route: '/Framework/User/Index',
    id: 103,
    parentId: 100,
  },
  {
    title: 'Users and Groups',
    key: 'UsersandGroups',
    icon: 'tablet-alt',
    description: 'Wizard for users management',
    route: '/Framework/User/IndexTree',
    id: 104,
    parentId: 100,
  },
  {
    title: 'Security Reports',
    key: 'SecurityReports',
    icon: 'tablet-alt',
    description: 'User Reports',
    route: '/Report/Index/Security',
    id: 105,
    parentId: 100,
  },
  {
    title: 'Integration',
    key: 'IntegrationMenu',
    icon: 'broadcast-tower',
    color: '#ef5b2b',
    route: '#',
    id: 41,
  },
  {
    title: 'Definitions',
    key: 'Definitions',
    icon: 'tablet-alt',
    description: 'Manage integration types',
    route: '/Framework/IntegrationDefinition/Index',
    id: 42,
    parentId: 41,
  },
  {
    title: 'Messages and Processes',
    key: 'MessagesandProcesses',
    icon: 'tablet-alt',
    description: 'Check integration logs',
    route: '/Framework/IntegrationProcess/Index',
    id: 43,
    parentId: 41,
  },
  {
    title: 'Administration',
    key: 'AdminMenu',
    icon: 'wrench',
    route: '#',
    id: 200,
  },
  {
    title: 'Documents',
    key: 'Documents',
    icon: 'tablet-alt',
    description: 'Manage all documents',
    route: '/Framework/DocumentFile/Index',
    id: 201,
    parentId: 200,
  },
  {
    title: 'Application Settings',
    key: 'ApplicationSettings',
    icon: 'tablet-alt',
    description: 'Set application settings',
    route: '/Framework/AppSettings/Index',
    id: 202,
    parentId: 200,
  },
  {
    title: 'Data Administration',
    key: 'DataAdministration',
    icon: 'tablet-alt',
    description: 'Query all data',
    route: '/Framework/DataAdmin/Index',
    id: 203,
    parentId: 200,
  },
  {
    title: 'Insights Viewer',
    key: 'InsightsViewer',
    icon: 'tablet-alt',
    description: 'Query application logs',
    route: '/Framework/InsightsViewer/Index',
    id: 204,
    parentId: 200,
  },
];
export const app_themes = [
  { id: 'light', text: 'Light' },
  { id: 'dark', text: 'Dark' },
];
